import {connect} from "react-redux"
import Header from "./Header"
import {uiActions} from "../../redux/ui-reducer";

const mapStateToProps = (state) => {

	return {
		navBarRef: state.navbar.navBarRef,
		isFetching: state.ui.isFetching,
		alertMessage: state.ui.alertMessage,
		messages: state.language.messages,
	}
}

export default connect(mapStateToProps, {clearAlertMessage: uiActions.clearAlertMessage})(Header)
