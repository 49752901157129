import {cookieAPI, resourcesAPI} from '../api/api'
import {uiActions, UiActionTypes} from "./ui-reducer";
import {BaseThunkType, InferActionsTypes} from "./redux-store";

// const SET_SECTION_DATA = 'SET_SECTION_DATA'
const SET_PAGE_DATA = 'SET_PAGE_DATA'
const SET_COOKIE_TEXT = 'SET_COOKIE_TEXT'

const initialState = {
	currentPageName: '',
	sectionsResponse: {},
	pageResponse: {},
	cookieText: {}
}

export type PageActionTypes = InferActionsTypes<typeof pageActions>
export type PageThunkType = BaseThunkType<PageActionTypes | UiActionTypes>
type InitialStateType = typeof initialState

const currentPage = (state = initialState, action: PageActionTypes): InitialStateType => {
	switch (action.type) {
		case SET_PAGE_DATA:
			return {
				...state,
				currentPageName: action.pageName,
				pageResponse: {
					...state.pageResponse,
					[action.pageName]: {
						response: action.response,
					}
				}
			}
		case SET_COOKIE_TEXT:
			return {
				...state,
				cookieText: action.cookieText

			}
		default:
			return state
	}
}


const pageActions = {
	setPageData: (response: object, pageName: string) => ({
		type: SET_PAGE_DATA,
		response: response,
		pageName: pageName
	}) as const,
	setCookieText: (response: object) => ({
		type: SET_COOKIE_TEXT,
		cookieText: response
	}) as const
}

export default currentPage

export const getPageData = (locale: string, params: object, pageName: string): PageThunkType => {
	return async (dispatch) => {
		dispatch(uiActions.setFetchingStatus(true))
		resourcesAPI.getPage(locale, params)
			.then((data: { code: number; object: object; }) => {
					if (data.code === 200) {
						dispatch(pageActions.setPageData(data.object, pageName))
						dispatch(uiActions.setFetchingStatus(false))
					}
				}
			)
	}
}

export const getPageWithChildrenData = (locale: string, params: object, pageName: string): PageThunkType => {
	return async (dispatch) => {
		dispatch(uiActions.setFetchingStatus(true))
		resourcesAPI.getPageWithChildren(locale, params)
				.then((data: {results: Array<any>}) => {
							if (data.results.length) {
								dispatch(pageActions.setPageData(data, pageName))
								dispatch(uiActions.setFetchingStatus(false))
							}
						}
				)
	}
}

export const getCookieText = (locale: string, params: object): PageThunkType => {
	return async (dispatch) => {
		cookieAPI.getCookieConsent(locale, params)
				.then((data: object) => {
					dispatch(pageActions.setCookieText(data))
				})
	}
}
