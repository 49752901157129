import React from "react"
import {NavLink} from "react-router-dom"
import {handleScrollToTop} from "../../Utils/handleScrollToTop";


const FooterPolicy = (props) => {
	const scrollToTop = () => {
		handleScrollToTop()
	}

	return (
		<div className="footer__policy">
			<ul className="nav flex-column">
				<li className="nav-item first">
					<NavLink to={`/${props.locale}/cookies-policy`} className="nav-link" onClick={scrollToTop}>
						{props.messages['terms_and_privacy']}
					</NavLink>
				</li>
			</ul>
		</div>
	);
}

export default FooterPolicy
