import {resourcesAPI} from '../api/api'
import {uiActions, UiActionTypes} from "./ui-reducer";
import {BaseThunkType, InferActionsTypes} from "./redux-store";
import {PageContentType, SectionContentType} from "../types/types";

const SET_SECTION_DATA = 'SET_SECTION_DATA'

const initialState = {
	sectionSliderIntro: {
		response: {},
		sectionName: 'sectionSliderIntro',
		params: {
			i18nResourcesId: {ru: 2, en: 74, lv: 77},
			includeTVs: 'intro_carousel,carousel_interval',
			processImages: '{"image_1":["f=jpeg&w=270","f=jpeg&w=540"]}',
			processMigxImages: '{"intro_carousel":{"article_image":["f=jpeg&w=540","f=jpeg&w=1120","f=jpeg&w=1920"]}}',
		}
	},
	sectionAboutMe: {
		sectionName: 'sectionAboutMe',
		response: {},
		params: {
			i18nResourcesId: {ru: 3, en: 75, lv: 78},
			includeTVs: '',
			processImages: '',
			processMigxImages: ''
		},
	},
	sectionSliderPortfolio: {
		response: {},
		sectionName: 'sectionSliderPortfolio',
		params: {
			i18nResourcesId: {ru: 4, en: 4, lv: 4},
			includeTVs: 'outside_link,show_on_first',
			processImages: '{"image_1":["f=jpeg&w=480","f=jpeg&w=680"]}',
			processMigxImages: '',
			where: '{"show_on_first":"Yes"}',
		},
		carouselMultipleSlides: 4,
	},
	pageData: {
		pageName: 'firstPage',
		params: {
			i18nResourcesId: {ru: 1, en: 35, lv: 39},
			includeTVs: 'page_description,page_keywords,title_addon',
			processImages: '{"image_1":["f=jpeg&w=968&h=504&zc=1"]}',
			processMigxImages: ''
		},
	},
	cookieData: {
		pageName: 'cookiePage',
		params: {
			i18nResourcesId: {ru: 1, en: 35, lv: 39},
			includeTVs: 'use_cookie',
			processImages: '',
			processMigxImages: ''
		},
	}
}

export type FirstPageActionTypes = InferActionsTypes<typeof firstPageActions>
export type FirstPageThunkType = BaseThunkType<FirstPageActionTypes | UiActionTypes>
type InitialStateType = {
	sectionSliderIntro: SectionContentType,
	sectionAboutMe: SectionContentType,
	sectionSliderPortfolio: SectionContentType,
	pageData: PageContentType,
	cookieData: PageContentType
}

const firstPage = (state = initialState, action: FirstPageActionTypes): InitialStateType => {

	switch (action.type) {
		case SET_SECTION_DATA:
			return {
				...state,
				[action.sectionName]: {
					...state[action.sectionName],
					response: action.response
				}
			}
		default:
			return state
	}
}

const firstPageActions = {

	setSectionData: (response: object, sectionName: keyof InitialStateType) => ({
		type: SET_SECTION_DATA,
		response: response,
		sectionName: sectionName
	}) as const
}


export default firstPage

export const getSectionData = (locale: string, params: object, sectionName: keyof InitialStateType): FirstPageThunkType => {
	return async (dispatch) => {
		resourcesAPI.getPage(locale, params)
				.then((data: { code: number; object: object; }) => {
							if (data.code === 200) {
								dispatch(firstPageActions.setSectionData(data.object, sectionName))
							}
						}
				)
				.then(() => {
					if (sectionName === 'sectionSliderIntro') {
						dispatch(uiActions.setFetchingStatus(false))
					}
				})
	}
}

export const getSectionChildrenData = (locale: string, params: object, sectionName:  keyof InitialStateType): FirstPageThunkType => {
	return async (dispatch) => {
		resourcesAPI.getPages(locale, params)
				.then((data: { results: object[]; }) => {
							if (data.results.length) {
								dispatch(firstPageActions.setSectionData(data.results, sectionName))
							}
						}
				)
	}
}
