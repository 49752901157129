import React from 'react'
import Nav from "react-bootstrap/Nav"
import {NavLink} from 'react-router-dom'
import {HashLink} from 'react-router-hash-link'
import {handleScrollToTop} from "../../Utils/handleScrollToTop"

const NavMain = (props) => {

	const scrollToTop = () => {
		handleScrollToTop()
	}

	const NavItems = props.navBarRoutes.map((route, index) => {
		return (
			<Nav.Link
				as={route['hash_link'] ? HashLink : NavLink}
				key={index}
				eventKey={route['uri']}
				to={route['uri'] === 'index' ? `/${props.locale}/` : route['uri']}
				{...route['hash_link'] ? null : {onClick: scrollToTop}}
				{...(route['hash_link'] ? {smooth: true} : {exact: true})}
			>
				{route['pagetitle']}
			</Nav.Link>
		)
	})

	return (
		<Nav className="mx-lg-auto">
			{NavItems}
		</Nav>
	)
}

export default NavMain
