import React from "react"
import CookieConsent from "react-cookie-consent"
import {connect} from "react-redux"
import {withCookieConsentText} from "../hoc/withCookieConsentText"
import {compose} from "redux"
import parse from 'html-react-parser'

const CookieConsentContainer = (props) => {

	if (props.pageResponse !== undefined) {
		return (
			<CookieConsent
				buttonText={props.messages['i_agree']}
				style={{background: "rgba(0,0,0,0.8)", padding: "2rem"}}
				disableButtonStyles={true}
				buttonClasses="btn btn-primary btn-lg"
			>
				<p className="mb-0">{props.pageResponse['use_cookie'] ? parse(props.pageResponse['use_cookie']) : ''}</p>
			</CookieConsent>
		)
	} else {
		return null
	}
}

const mapStateToProps = (state) => {
	return {
		pageData: state.firstPage.cookieData,
		pageResponse: state.currentPage.cookieText,
		locale: state.language.locale,
		messages: state.language.messages
	}
}

export default compose(connect(mapStateToProps), withCookieConsentText)(CookieConsentContainer)