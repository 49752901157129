import React from 'react'
import {Formik} from 'formik'
import * as yup from 'yup'
import Form from "react-bootstrap/Form"
import FooterFormFields from "./FooterFormFields"
import Button from "react-bootstrap/Button"

const FooterForm = (props) => {

	const handleYup = Object.fromEntries(
		props.formFields.map(item => [item.name, item.yupObject])
	)

	const handleFormSubmit = () => {
		props.addFormData(props.formValues)
	}

	const handleOnChange = (values) => {
		props.updateFormData(values)
	}

	const initialValues = () => {
		return Object.keys(props.formValues).length ? props.formValues : {}
	}

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues()}
			validationSchema={
				yup.object(handleYup)
			}
			onSubmit={handleFormSubmit}
			validate={handleOnChange}
		>
			{({
				  handleChange,
				  handleSubmit,
				  values,
				  touched,
				  errors,
			  }) => (
				<Form
					className="form form--dark"
					method="post"
					noValidate
					onSubmit={handleSubmit}
				>
					{props.formFields.map(formField => {
						return <FooterFormFields
							key={formField.name}
							formField={formField}
							onChange={handleChange}
							onErrors={errors[formField.name]}
							isValid={touched[formField.name] && !errors[formField.name]}
							value={values[formField.name] ?? ''}
							messages={props.messages}
						/>
					})}
					<div className="form-footer text-center mt-5">
						<Button
							type="submit"
							variant="outline-light"
							size="lg"
						>
							{props.messages['Send']}
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default FooterForm
