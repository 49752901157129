import {connect} from "react-redux"
import NavBar from "./NavBar"
import {getNavBarRoutes} from "../../redux/navbar-reducer"
import {setLocale} from "../../redux/language-reducer"
import React from "react"

class NavBarContainer extends React.Component {
	componentDidMount() {
		this.props.getNavBarRoutes(this.props.locale)
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.locale !== this.props.locale) {
			this.props.getNavBarRoutes(this.props.locale)
		}
	}

	render() {
		if (this.props.locale) {
			return <NavBar {...this.props}/>
		} else {
			return null
		}
	}
}

const mapStateToProps = (state) => {
	return {
		navBarRoutes: state.navbar.navBarRoutes,
		locale: state.language.locale,
		languagesList: state.language.languagesList
	}
}

export default connect(mapStateToProps, {setLocale, getNavBarRoutes})(NavBarContainer)


