import React from 'react'
import NavBarContainer from "../NavBar/NavBarContainer"
import Preloader from "../Preloader/Preloader"
import AlertMessage from "../AlertMessage/AlertMessage"

const Header = (props) => {

	const handleAlertMessage = () => {
		if (Object.keys(props.alertMessage).length) {
			return (
				<AlertMessage
					alertMessage={props.alertMessage}
					clearAlertMessage={props.clearAlertMessage}
					messages={props.messages}
				/>
			)
		}
		return null
	}

	return (
		<>
			<NavBarContainer/>
			{handleAlertMessage()}
			<Preloader isFetching={props.isFetching} />
		</>
	)
}

export default Header;
