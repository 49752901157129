import 'react-app-polyfill/stable'
import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from "react-router-dom"
import {Provider} from "react-redux"
import store from "./redux/redux-store"
import './scss/style.scss'
import App from './components/App/App'

const rootElement = document.getElementById("root")

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<App/>
			</Provider>
		</BrowserRouter>
	</React.StrictMode>,
	rootElement
)

//basename={`/${store.getState().language.locale}`}