import {
	sendFormData,
	updateFormData
} from "../../redux/footer-reducer"
import {connect} from "react-redux"
import Footer from "./Footer"

const mapStateToProps = (state) => {
	return {
		formValues: state.footer.formValues,
		formFields: state.footer.formFields,
		contactsData: state.footer.contactsData,
		contactsCopy: state.footer.contactsCopy,
		messages: state.language.messages,
		locale: state.language.locale
	}
}

export default connect(mapStateToProps,{
	sendFormData,
	updateFormData,
})(Footer)

