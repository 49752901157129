import * as axios from "axios";

const BASE_URL = 'https://developer.lv/assets/components/rest/'

const instance = axios.create(
	{
		baseURL: BASE_URL,
		headers:{
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		}
	}
)

const formPost = axios.create(
	{
		baseURL: BASE_URL,
		headers:{
			'Accept': 'application/x-www-form-urlencoded',
			'Content-Type': 'application/x-www-form-urlencoded',
		}
	}
)

export const resourcesAPI = {
	getPage(locale, params) {
		return instance.get(`resources/${params.i18nResourcesId[locale]}`, {
			params: {
				includeTVs: params.includeTVs,
				processMigxImages: encodeURIComponent(params.processMigxImages),
				processImages: encodeURIComponent(params.processImages),
			}
		})
			.then(response => {
				return response.data
			})
	},

	getPages(locale, params) {
		return instance.get(`resources`, {
			params: {
				parents: params.i18nResourcesId[locale],
				includeTVs: params.includeTVs,
				processImages: encodeURIComponent(params.processImages),
				getXTotal: params.getXTotal,
				limit: params.limit,
				offset: params.offset,
				where: params.where
			}
		})
			.then(response => {
				return response.data
			})
	},
	getPageWithChildren(locale, params) {
		let allResponses = {}
		return resourcesAPI.getPage(locale, params)
			.then(page => {
				allResponses = {...page.object}
				return resourcesAPI.getPages(locale, params)
					.then(pages => {
						allResponses = {...allResponses,...pages}
						return allResponses
					})
			})
	},
	getMainRoutes(context) {
		if (context === 'ru') context = 'web'
		return instance.get(`resources`, {
			params: {
				context: context,
				parents: 0,
				pdoMenu: 1,
				includeTVs: 'hash_link'
			}
		})
			.then(response => {
				return response.data
			})
	}
}

export const cookieAPI = {
	getCookieConsent(locale, params) {
		return resourcesAPI.getPage(locale, params)
			.then(response => {
				return response.object
			})
	}
}

export const formItAPI = {
	contactForm(formData) {
		const body = new URLSearchParams(formData)
		return formPost.post(`contactform`, body)
			.then(response => {
				return response.data
			})
	}
}