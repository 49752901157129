import {setLocaleFromUrl} from "./language-reducer";
import {BaseThunkType, InferActionsTypes} from "./redux-store";
import {AlertMessageType} from "../types/types";

const SCREEN_RESIZE = 'SCREEN_RESIZE'
const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE'
const CLEAR_ALERT_MESSAGE = 'CLEAR_ALERT_MESSAGE'
const SET_FETCHING_STATUS = 'SET_FETCHING_STATUS'
const INITIALIZED_SUCCESS = 'INITIALIZED_SUCCESS'


const initialState = {
	screenWidth: typeof window === 'object' ? window.innerWidth : 0 as number,
	isFetching : true,
	alertMessage: {} as AlertMessageType,
	dataUrl: 'https://developer.lv',
	initialized: false,
}

type InitialStateType = typeof initialState
export type UiActionTypes = InferActionsTypes<typeof uiActions>
export type UiThunkType = BaseThunkType<UiActionTypes>

const ui = (state = initialState, action: UiActionTypes): InitialStateType => {
	switch (action.type) {
		case INITIALIZED_SUCCESS:
			return {
				...state,
				initialized: true,
			}
		case SCREEN_RESIZE:
			return Object.assign({}, state, {
				...state,
				screenWidth: action.screenWidth
			})
		case SET_FETCHING_STATUS:
			return {
				...state,
				isFetching: action.isFetching
			}
		case CLEAR_ALERT_MESSAGE: {
			return {
				...state,
				alertMessage: {} as AlertMessageType
			}
		}
		case SET_ALERT_MESSAGE: {
			if (action.alertMessage.success) {
				action.alertMessage.messageType = 'success'
			} else {
				action.alertMessage.messageType = 'error'
			}
			return {
				...state,
				alertMessage: action.alertMessage
			}
		}
		default:
			return state
	}
}

export const uiActions = {
	setAlertMessage: (alertMessage: AlertMessageType) => ({
		type: SET_ALERT_MESSAGE,
		alertMessage: alertMessage
	}) as const,
	clearAlertMessage: () => ({
		type: CLEAR_ALERT_MESSAGE
	}) as const,
	setFetchingStatus: (isFetching: boolean) => ({
		type: SET_FETCHING_STATUS,
		isFetching: isFetching
	}) as const,
	screenResize: (screenWidth: number) => ({
			type: SCREEN_RESIZE,
			screenWidth: screenWidth
	}) as const,
	initializedSuccess: () => ({
		type: INITIALIZED_SUCCESS
	}) as const
}

export const initializeApp = (pathname: string, locale: string): UiThunkType => {
	return async (dispatch) => {
		let promise = []
		if (!locale) {
			promise = dispatch(setLocaleFromUrl(pathname))
		}
		Promise.all([promise])
				.then(() => {
					dispatch(uiActions.initializedSuccess())
				})
	}
}

export default ui
