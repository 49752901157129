import NotFound from "../NotFound/NotFound";
import {connect} from "react-redux";
import {uiActions} from "../../redux/ui-reducer";

const mapStateToProps = (state) => {
	return {
		locale: state.language.locale,
	}
}

export default connect(mapStateToProps, {setFetchingStatus: uiActions.setFetchingStatus()})(NotFound)
