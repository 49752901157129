import React from 'react'
import Navbar from "react-bootstrap/Navbar"
import NavMain from "./NavMain/NavMain"
import {Link} from 'react-router-dom'
import NavLang from "./NavLang/NavLang"

const NavBar = (props) => {

	return (
		<Navbar bg="light" expand="lg" sticky="top" collapseOnSelect>
			<Navbar.Brand as={Link} to={`/${props.locale}/`}>D</Navbar.Brand>
			<Navbar.Toggle aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation"/>
			<Navbar.Collapse id="navbarContent">
				<NavMain
					navBarRoutes={props.navBarRoutes}
					locale={props.locale}
				/>
				<NavLang
					languagesList={props.languagesList}
					locale={props.locale}
					setLocale={props.setLocale}
				/>
			</Navbar.Collapse>
		</Navbar>
	);
}

export default NavBar
