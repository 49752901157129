import React from 'react'
import Form from 'react-bootstrap/Form'

const FooterFormFields = ({formField, onChange, onErrors, isValid, value, messages}) => {

	const handleControlType = () => {
		switch (formField.type) {
			case 'textarea':
				return (
					<Form.Control
						as={formField.type}
						rows="3"
						size="lg"
						name={formField.name}
						onChange={onChange}
						isInvalid={!!onErrors}
						isValid={isValid}
						className={!value ? '' : 'has-value'}
						value={value}
					/>
				)
			default:
				return (
					<Form.Control
						type={formField.type}
						size="lg"
						name={formField.name}
						onChange={onChange}
						isInvalid={!!onErrors}
						isValid={isValid}
						className={!value ? '' : 'has-value'}
						value={value}
					/>
				)
		}
	}

	return (
		<Form.Group controlId={formField.name} className={formField.name === 'user_phone' ? 'd-none' : ''} aria-hidden={formField.name === 'user_phone'}>
			{handleControlType()}
			<Form.Label size="lg"><span>{messages[formField.label]}</span></Form.Label>
			<Form.Control.Feedback type="invalid">
				<span>{messages[onErrors]}</span>
			</Form.Control.Feedback>
			<Form.Control.Feedback>{messages['all_correct']}!</Form.Control.Feedback>
		</Form.Group>
	)
}

export default FooterFormFields

