import React from 'react'
import Mailto from 'react-protected-mailto'
import FooterPolicy from "../FooterPolicy/FooterPolicy";

const FooterContacts = (props) => {

	const handleMailTo = (type, url, text) => {
		switch (type) {
			case 'tel':
				return (<Mailto tel={url}>{text}</Mailto>)
			case 'email':
				return (<Mailto email={url}>{text}</Mailto>)
			default:
				return (<a href={url} target="blank">{text}</a>)
		}
	}

	const ContactsItems = props.contactsData.map((item, index) =>
		<li
			key={index}
		>
			<span className="contacts__icon" aria-hidden="true">
				<svg className={`icon icon-${item.icon}`}><use href={`#icon-${item.icon}`}/></svg>
			</span>

			{handleMailTo(item.type, item.url, item.text)}
		</li>
	)


	return (
		<>
			<ul className="footer__contacts">
				{ContactsItems}
			</ul>

			<p className="mt-5">&copy;{new Date().getFullYear()} {props.contactsCopy}</p>

			<FooterPolicy
				messages={props.messages}
				locale={props.locale}
			/>
		</>


	);
}

export default FooterContacts
