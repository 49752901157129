import {resourcesAPI} from "../api/api";

const SET_NAVBAR_ROUTES = 'SET_NAVBAR_ROUTES'

const initialState = {
	navBarRoutes: [],
}

const navbar = (state = initialState, action) => {

	switch (action.type) {
		case SET_NAVBAR_ROUTES:
			return {
				...state,
				navBarRoutes: action.data
			}
		default:
			return state
	}
}

const setNavBarRoutes = (data) => ({
	type: SET_NAVBAR_ROUTES,
	data: data
})

export default navbar

export const getNavBarRoutes = (locale) => (dispatch) => {
	resourcesAPI.getMainRoutes(locale)
		.then(data => {
				if (data.results.length) {
					dispatch(setNavBarRoutes(data.results))
				}
			}
		)
}

