import React from 'react'
import Nav from "react-bootstrap/Nav"
import {handleScrollToTop} from "../../Utils/handleScrollToTop"
import {NavLink} from 'react-router-dom'
import { useLocation } from "react-router-dom";

const NavLang = (props) => {

	const location = useLocation();

	const handleLocale = (locale) => {
		handleScrollToTop()
		props.setLocale(locale)
	}

	const NavItems =  props.languagesList.map((lang, index) =>
		<Nav.Link
			as={NavLink}
			className={`nav-link font-weight-bold ${props.locale === lang.locale ? 'active': ''}`}
			key={index}
			eventKey={lang.locale}
			onClick={() => handleLocale(lang.locale)}
			to={location.pathname.replace(`/${props.locale}/`, `/${lang.locale}/`)}
		>
			{lang.name}
		</Nav.Link>
	)

	return (
		<Nav as="div" className="navbar-lang navbar-nav" navbar={false}>
			{NavItems}
		</Nav>

	)
}

export default NavLang
