import * as yup from "yup";
import {formItAPI} from "../api/api";
import {uiActions} from "./ui-reducer";

const FORM_DATA_CLEAR = 'FORM_DATA_CLEAR'
const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA'
const FORM_DATA_RESPONSE = 'FORM_DATA_RESPONSE'

const alphaCheck = /^[^ ]+/;

const initialState = {
	formValues: {},
	formFields: [
		{
			label: 'Name',
			name: 'user_name',
			type: 'text',
			isRequired: true,
			yupObject: (yup.string().matches(alphaCheck, {
				message: 'enter_valid_name',
				excludeEmptyString: true
			}).required('field_required'))
		},
		{
			label: 'Email',
			name: 'user_email',
			type: 'email',
			isRequired: true,
			yupObject: (yup.string().required('field_required').email('enter_valid_email'))
		},
		{
			label: 'Phone',
			name: 'user_phone',
			type: 'tel',
			yupObject: ''
		},
		{
			label: 'Message',
			name: 'user_message',
			type: 'textarea',
			isRequired: true,
			yupObject: (yup.string().matches(alphaCheck, {
				message: 'enter_valid_message',
				excludeEmptyString: true
			}).required('field_required'))
		}
	],
	formResponse: {},
	contactsData: [
		{type: 'tel', url: '+37129809792', text: '+371 29809792', icon: 'phone'},
		{type: 'email', url: 'janis@developer.lv', text: 'janis@developer.lv', icon: 'mail'},
	],
	contactsCopy: 'Developer.lv / WEB development'
}

const footer = (state = initialState, action) => {

	switch (action.type) {
		case FORM_DATA_CLEAR:
			return {
				...state,
				formValues: {},
			}
		case FORM_DATA_RESPONSE:
			return {
				...state,
				formResponse: action.formResponse
			}
		case UPDATE_FORM_DATA:
			return {
				...state,
				formValues: action.formValues
			}
		default:
			return state
	}
}

export const formDataClear = () => ({
	type: FORM_DATA_CLEAR,
})
export const formDataResponse = (data) => ({
	type: FORM_DATA_RESPONSE,
	formResponse: data
})
export const updateFormData = (values) => ({
	type: UPDATE_FORM_DATA,
	formValues: values
})

export default footer

export const sendFormData = (formData) => (dispatch) => {
	formItAPI.contactForm(formData)
		.then(data => {
			if (data.success) {
				dispatch(formDataResponse(data.object))
				dispatch(uiActions.setAlertMessage(data.object['formit']))
				if (data.object['formit']['success']) {
					dispatch(formDataClear())
				}
			}
		})
		.catch(error => {
			const alertMessage = {
				messageText: error.message,
				messageType: 'error'
			}
			dispatch(uiActions.setAlertMessage(alertMessage))
		})

}
