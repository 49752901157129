import {Action, applyMiddleware, combineReducers, createStore} from "redux"
import footer from "./footer-reducer"
import navbar from "./navbar-reducer"
import firstPage from "./first-page-reducer"
import skillsPage from "./skills-reducer"
import portfolioPage from "./portfolio-reducer";
import thunkMiddleware, {ThunkAction} from "redux-thunk"
import language from "./language-reducer";
import currentPage from "./page-reducer";
import ui from "./ui-reducer";
import termsPage from "./terms-reducer";

let reducers = combineReducers({
	language,
	footer,
	navbar,
	firstPage,
	skillsPage,
	portfolioPage,
	termsPage,
	currentPage,
	ui,
})

const store = createStore(reducers, applyMiddleware(thunkMiddleware))

// @ts-ignore
//window.__store__ = store

export default store
type RootReducerType = typeof reducers // (globalstate: AppStateType) => AppStateType
export type AppStateType = ReturnType<RootReducerType>
export type InferActionsTypes<T> = T extends { [key: string]: (...args: any[]) => infer U } ? U : never
export type BaseThunkType<A extends Action, R = Promise<void>> = ThunkAction<R, AppStateType, unknown, A>
