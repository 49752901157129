const SET_PORTFOLIO_OFFSET = 'SET_PORTFOLIO_OFFSET'

const initialState = {
	pageData: {
		pageName: 'portfolioPage',
		params: {
			getXTotal: 1,
			offset: 0,
			limit: 12,
			i18nResourcesId: {ru: 4, en: 4, lv: 4},
			includeTVs: 'outside_link,page_description,page_keywords,title_addon',
			processImages: '{"image_1":["f=jpeg&w=968&h=504&zc=1","f=jpeg&w=560","f=jpeg&w=1120","f=jpeg&w=1920"]}',
		}
	},
}

const portfolioPage = (state = initialState, action) => {
	switch (action.type) {
		case SET_PORTFOLIO_OFFSET:

			let newParams = {...state.pageData.params, offset: action.offset}
			return {
				...state,
				pageData: {
					...state.pageData,
					params: newParams
				}
			}
		default:
			return state
	}
}

export const setPortfolioPage = (offset, locale) => ({
	type: SET_PORTFOLIO_OFFSET,
	offset: offset,
	locale: locale
})

export const setPortfolioInfo = (offset, locale) => (dispatch) => {
	dispatch(setPortfolioPage(offset, locale))
}

export default portfolioPage