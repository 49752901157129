import React from 'react'
import './Preloader.scss'

const Preloader = (props) => {

	const preloaderRef = React.useRef();

	const handlePreloader = () => {
		const element = preloaderRef.current
		if (props.isFetching) {
			element.style.display = 'block'
			setTimeout(() => {
				element.classList.remove("fading")
			}, 50)
		} else {
			element.classList.add("fading")
			setTimeout(() => {
				element.style.display = 'none'
			}, 450)
		}
	}

	React.useEffect(() => {
		handlePreloader()
	})

	return (
		<div className="preloader fading" ref={preloaderRef}>
			<div className="preloader__item">
				<div className="preloader__ripple preloader__ripple_1" />
				<div  className="preloader__ripple preloader__ripple_2" />
			</div>
		</div>
	);
}

export default Preloader;
