import React from 'react'
import Jumbotron from "react-bootstrap/Jumbotron";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";


const NotFound = (props) => {

	const handleIsFetching = () => {
		props.setFetchingStatus(false)
	}
	React.useEffect(() => {
		handleIsFetching()
	})

	return (
		<>
			<Helmet
				title="404"
			/>
			<main className="d-flex justify-content-center align-items-center vh-100">
				<Jumbotron className="p-5 m-0">
					<h1>Page not found!</h1>
					<NavLink to={`/${props.locale}/`} className="btn btn-primary mt-4">Back to Home</NavLink>
				</Jumbotron>
			</main>
		</>
	)
}

export default NotFound