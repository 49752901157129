import Messages from '../locales/messages.json'

const DEFAULT_LOCALE = 'lv'
const SET_LOCALE = 'UPDATE_LANGUAGE'

const initialState = {
	locale: null,
	messages: {},
	// base: 'http://localhost:3000/',
	base: 'https://developer.lv/',
	languagesList: [
		{name: 'en', locale: 'en'},
		{name: 'ru', locale: 'ru'},
		{name: 'lv', locale: 'lv'}
	]
}

const language = (state = initialState, action) => {
	switch (action.type) {
		case SET_LOCALE:
			return {
				...state,
				locale: action.locale,
				messages: Messages[action.locale]
			}
		default:
			return state
	}
}

export const setLocale = (locale) => ({
	type: SET_LOCALE,
	locale: locale
})

export const setLocaleFromUrl = (pathname) => (dispatch) => {
	let locale = pathname.split('/')[1]
	let localesArr = [...initialState.languagesList.map(item => item.locale)]
	if (localesArr.indexOf(locale) === -1) locale = DEFAULT_LOCALE
	return dispatch(setLocale(locale))
}

export default language
