import React from 'react'
import IconsSVG from "../IconsSVG/IconsSVG"
import {NavLink} from "react-router-dom"
import FooterContacts from "./FooterContacts/FooterContacts"
import FooterForm from "./FooterForm/FooterForm"

const Footer = (props) => {
	return (
		<footer className="footer" id="footer">
			<div className="container">

				<div className="d-flex justify-content-center">
					<h2 className="footer__title"><NavLink exact to="/">Developer.lv</NavLink></h2>
				</div>

				<div className="row">

					<div className="col-12 col-md-6 pr-lg-5">
						<FooterForm
							formValues={props.formValues}
							formFields={props.formFields}
							addFormData={props.sendFormData}
							updateFormData={props.updateFormData}
							messages={props.messages}
						/>
					</div>

					<div className="col-12 col-md-6 pl-lg-5">
						<FooterContacts
							contactsData={props.contactsData}
							contactsCopy={props.contactsCopy}
							messages={props.messages}
							locale={props.locale}
						/>
					</div>

				</div>
			</div>

			<IconsSVG/>

		</footer>
	)
}

export default Footer
