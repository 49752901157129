import React from 'react'
import {Redirect, Route, Switch, withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {Cookies} from "react-cookie-consent"
import {compose} from "redux";
import {initializeApp, uiActions} from "../../redux/ui-reducer"
import {setLocale} from "../../redux/language-reducer"

import FooterContainer from "../Footer/FooterContainer"
import HeaderContainer from "../Header/HeaderContainer"
import CookieConsentContainer from "../CookieConsent/CookieConsentContainer"
import Preloader from "../Preloader/Preloader";
import NotFoundContainer from "../NotFound/NotFoundContainer";

const PortfolioPageContainer = React.lazy(() => import("../Portfolio/PortfolioPageContainer"))
const SkillsContainer = React.lazy(() => import("../Skills/SkillsContainer"))
const TermsContainer = React.lazy(() => import("../TermsAndPrivacy/TermsContainer"))
const FirstPageContainer = React.lazy(() => import("../FirstPage/FirstPageContainer"))


class App extends React.Component {

	handleScreenResize = () => {
		this.props.screenResize(window.innerWidth)
	}

	handleInitialize = () => {
		this.props.initializeApp(this.props.location.pathname, this.props.locale)
	}

	componentDidMount() {
		this.handleInitialize()
		window.addEventListener('resize', this.handleScreenResize, {passive: true})
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleScreenResize)
	}

	render() {

		if (this.props.initialized) {

			return (
				<>
					{Cookies.get('CookieConsent') ? null : <CookieConsentContainer/>}
					<HeaderContainer/>


						<React.Suspense fallback={<Preloader/>}>

					<Switch>
						<Route
							path='/'
							exact
							render={() => <Redirect to={`/${this.props.locale}/`}/>}
						/>

						<Route
								path={`/${this.props.locale}/`}
								exact
								render={() => <FirstPageContainer/>}
							/>

							<Route
								path={`/${this.props.locale}/skills`}
								render={() => <SkillsContainer/>}
							/>

							<Route
								path={`/${this.props.locale}/portfolio`}
								render={() => <PortfolioPageContainer/>}
							/>

							<Route
								path={`/${this.props.locale}/cookies-policy`}
								render={() => <TermsContainer/>}
							/>

							<Route
								render={() => <NotFoundContainer/>}
							/>

					</Switch>
						</React.Suspense>
					<FooterContainer/>
				</>
			)
		} else {
			return <Preloader isFetching={true}/>
		}
	}
}

const mapStateToProps = (state) => {
	return {
		locale: state.language.locale,
		initialized: state.ui.initialized
	}
}

export default compose(connect(mapStateToProps, {screenResize: uiActions.screenResize, setLocale, initializeApp}), withRouter)(App)
