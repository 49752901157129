import React from 'react'
import Alert from "react-bootstrap/Alert";

const AlertMessage = (props) => {

	const [showAlert, setShowAlert] = React.useState(true)
	const handClearAlertMessage = () => {
		setShowAlert(false)
		setTimeout (() => {
			props.clearAlertMessage()
		}, 300)
	}

	return (
		<Alert variant={props.alertMessage.messageType} className="fixed-top" onClose={handClearAlertMessage} dismissible show={showAlert}>
			<Alert.Heading className="text-uppercase">{props.messages[props.alertMessage.messageType]}</Alert.Heading>
			<p className="mb-0">{props.messages[props.alertMessage.message] ?? props.alertMessage.messageText}</p>
		</Alert>
	)
}

export default AlertMessage