import React from 'react'
import {connect} from "react-redux"
import {getCookieText} from "../../redux/page-reducer";

const mapStateToPropsForData = (state) => ({
})

export const withCookieConsentText = (Component) => {

	class PageComponent extends React.Component {

		loadCookieText() {
			this.props.getCookieText(
				this.props.locale,
				this.props.pageData.params,
			)
		}

		componentDidMount() {
			this.loadCookieText()
		}

		componentDidUpdate(prevProps, prevState, snapshot) {
			if (prevProps.locale !== this.props.locale) {
				this.loadCookieText()
			}
		}

		render() {
			return <Component {...this.props}/>
		}
	}

	return connect(mapStateToPropsForData, {getCookieText})(PageComponent)

}

