const initialState = {
	pageData: {
		pageName: 'termsPage',
		params: {
			i18nResourcesId: {ru: 34, en: 45, lv: 43},
			includeTVs: 'page_description,page_keywords,title_addon',
			processImages: '{"image_1":["f=jpeg&w=968&h=504&zc=1","f=jpeg&w=560","f=jpeg&w=1120","f=jpeg&w=1920"]}',
		},
	}
}

const termsPage = (state = initialState, action) => {
	switch (action.type) {
		default:
			return state
	}
}

export default termsPage

